<template>
  <div>
    <CRow class="mb-3">
      <CCol md="3">
        <CCard class="h-100">
          <CCardBody>
            <div class="ui-type-display-xl">{{ round.count_orders }}</div>
            <div class="ui-type-display-lg">Ordini</div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="3">
        <CCard class="h-100">
          <CCardBody>
            <div class="ui-type-display-xl">{{ round.count_prods }}</div>
            <div class="ui-type-display-lg">Articoli</div>
            <div class="ui-type-subheading text-warning">{{ prodsInfo() }}</div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="3">
        <CCard class="h-100">
          <CCardBody>
            <div class="ui-type-display-xl">{{ round.count_producers }}</div>
            <div class="ui-type-display-lg">Produttori</div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CRow>
      <CCol md="3">
        <CCard class="h-100">
          <CCardBody>
            <div class="ui-type-display-xl">{{ round.count_wh }}</div>
            <div class="ui-type-display-lg">Magazzini</div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol md="3">
        <CCard class="h-100">
          <CCardBody>
            <div class="ui-type-display-xl">{{ round.count_islands }}</div>
            <div class="ui-type-display-lg">Isole</div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getOrderStatusOptions } from "../../../../../../helpers/options";
export default {
  name: "ViewOverview",

  computed: {
    ...mapState("rounds", ["round"]),
  },

  methods: {
    prodsInfo() {
      const info = [];
      const status = getOrderStatusOptions();
      const { tot_status } = this.round;
      Object.keys(tot_status).forEach((key) => {
        const s = status.find((i) => i.value == key);
        if (s) {
          info.push(`${tot_status[key]} ${s.label}`);
        }
      });

      return info.join(" - ");
    },
  },
};
</script>
